@import url("https://fonts.googleapis.com/css?family=Press+Start+2P");
@import url("https://fonts.googleapis.com/css?family=VT323");
@import url("https://fonts.googleapis.com/css2?family=Cinzel:wght@500;700&display=swap");

.App {
  text-align: center;
}

* {
  box-sizing: border-box;
}

.font-cinzel {
  font-family: "Cinzel", serif !important;
}

.container {
  height: 100%;
}

.moving-background {
  z-index: -2;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("./assets/mainContent/background.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: scroll;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.notification-animation {
  animation: slide-in-right 0.5s ease-in-out;
}

@keyframes slide-in-right {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes easeInOut {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0);
  }
}

.text-one {
  color: white;
  font-family: Arial, sans-serif;
  margin-top: 100px;
  /* moves the text down by 10 pixels */
  margin-left: 80px;
  /* moves the text left by 20 pixels */
}

.link-hover {
  transition: transform 0.2s ease-in-out;
}

.link-hover:hover {
  transform: scale(1.1);
}

.fade-in {
  animation: fadeIn 1s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
