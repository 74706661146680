@import url(https://fonts.googleapis.com/css?family=Press+Start+2P);
@import url(https://fonts.googleapis.com/css?family=VT323);
@import url(https://fonts.googleapis.com/css2?family=Cinzel:wght@500;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button {
  -webkit-appearance: none;
          appearance: none;
  background: none;
  border: none;
}

ol,
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.App {
  text-align: center;
}

* {
  box-sizing: border-box;
}

.font-cinzel {
  font-family: "Cinzel", serif !important;
}

.container {
  height: 100%;
}

.moving-background {
  z-index: -2;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url(/static/media/background.39230201.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: scroll;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.notification-animation {
  -webkit-animation: slide-in-right 0.5s ease-in-out;
          animation: slide-in-right 0.5s ease-in-out;
}

@-webkit-keyframes slide-in-right {
  from {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }

  to {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@keyframes slide-in-right {
  from {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }

  to {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@-webkit-keyframes easeInOut {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }

  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@keyframes easeInOut {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }

  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

.text-one {
  color: white;
  font-family: Arial, sans-serif;
  margin-top: 100px;
  /* moves the text down by 10 pixels */
  margin-left: 80px;
  /* moves the text left by 20 pixels */
}

.link-hover {
  transition: -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
}

.link-hover:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.fade-in {
  -webkit-animation: fadeIn 1s;
          animation: fadeIn 1s;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

